import "./App.css";
import { MisRutas } from "./router/MisRutas";
function App() {
  return (
    <>
      <div className="layout">
        <MisRutas />
      </div>
    </>
  );
}

export default App;
